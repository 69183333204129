import React, { ReactElement, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Autocomplete, Box, Checkbox, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateSegmentFulfillmentModalAction, showCreateSuccessModalAction } from '../../../redux/actions/modalActions/modalActions';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { createSegmentFulfillmentJob, CreateSegmentFulfillmentJobAPIPayload } from '../../../services/fulfillment.service';
import { FetchAllSegmentsData, tagType, getSegmentList } from '../../../services/segment.services';

import InputFieldsTooltip from '../../InputFieldsTooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useCurrentPartner } from '../../../hooks/useCurrentPartner';
import DateWrapper from '../../UI/DateWrapper';
import CSVReader from '../../UI/CsvReader';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateFulfillmentJob = (): ReactElement => {
  const { partnerShortName } = useCurrentPartner();
  const showSegmentFulfillmentModalData = useSelector((state: RootState) => state.modalReducer.showCreateSegmentFulfillmentJob);
  const currentUser = useSelector((state: RootState) => state.loginReducer);

  // const [formData, setFormData] = useState<CreateSegmentFulfillmentJobAPIPayload>();
  const [Tags, setTags] = useState<tagType[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [selectedTagLabels, setSelectedTagLabels] = useState<string[]>([]);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [userCount, setUserCount] = useState(0);
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [isRemoveAction, setIsRemoveAction] = useState<boolean>(false);
  const [showEmptyTags, setShowEmptyTags] = useState<boolean>(false);

  const dispatch = useDispatch();

  const useStyles = makeStyles({
    divider: {
      backgroundColor: 'black',
      borderBottomWidth: 2,
      marginTop: '25px',
      marginBottom: '25px',
    },
    leftGrid: {
      marginRight: '90px',
      marginLeft: '20px',
      flexDirection: 'column',
    },
    rightGrid: {
      marginRight: '20px',
      marginLeft: '90px',
      flexDirection: 'column',
    },
    updateStatusButton: {
      backgroundColor: 'rgb(50, 205, 50)',
      color: 'black',
      width: '250px',
      height: '50px',
      textTransform: 'none',
    },
    gridStyle: {
      marginBottom: '25px',
    },
  });

  const classes = useStyles();
  const handleClose = () => {
    dispatch(showCreateSegmentFulfillmentModalAction(false, null));
    clearData();
  };

  const clearData = () => {
    setIsRemoveAction(false);
    setIsScheduled(false);
  };

  const handleSchedule = () => {
    const willBeScheduled = isScheduled ? false : true;
    setIsScheduled(willBeScheduled);
  };

  const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRemoveAction(e.target.checked);
  };

  const onUploadAccepted = (userIdList: number[]) => {
    console.log('---------------------------');

    setUserCount(userIdList.length);
    setUserIds(userIdList);

    console.log('---------------------------');
  };

  const handleAutoComplete = async (event: React.SyntheticEvent, value: any | null) => {
    console.log({ selectedTags });

    const selectedTagIds: number[] = [];
    const selectedTagLabels: string[] = [];
    for (let i = 0; i < value.length; i++) {
      const selectedTagId: number = value[i].value;
      const selectedTagLabel: string = value[i].label;

      selectedTagIds.push(selectedTagId);
      selectedTagLabels.push(selectedTagLabel);
    }

    await setSelectedTagLabels(selectedTagLabels);
    await setSelectedTags(selectedTagIds);

    console.log({ selectedTagIds });
  };

  const handleEmptyTagsToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const tagsResponse = await getSegmentList({ partnerId: partnerShortName, status: 'active', no_users: true });

      const tags = tagsResponse.data?.map((tags: FetchAllSegmentsData) => {
        return {
          value: tags.segment_id,
          label: tags.internal_name,
          description: tags.internal_description || 'No Description',
        };
      });
      setTags([...tags]);
      setShowEmptyTags(true);
    } else {
      const tagsResponse = await getSegmentList({ partnerId: partnerShortName, status: 'active', no_users: false });

      const tags = tagsResponse.data?.map((tags: FetchAllSegmentsData) => {
        return {
          value: tags.segment_id,
          label: tags.internal_name,
          description: tags.internal_description || 'No Description',
        };
      });
      setTags([...tags]);
      setShowEmptyTags(false);
    }
  };

  const handleSubmit = async (value: { Start: Date }) => {
    console.log({ value });
    console.log({ currentUser });
    try {
      const upsertData: CreateSegmentFulfillmentJobAPIPayload = {
        data: {
          segmentJob: {
            isScheduled,
            startAt: new Date(value.Start),
            userCount: userCount,
            userIdList: userIds,
            tags: selectedTags,
            tagLabels: selectedTagLabels,
            action: isRemoveAction ? 'Remove' : 'Add',
            createdBy: {
              id: currentUser.data?.sub,
              email: currentUser.data?.mail,
              useName: currentUser.data?.userName,
            },
          },
        },
      };
      const res = await createSegmentFulfillmentJob(upsertData);
      if (res) {
        dispatch(showCreateSuccessModalAction(false, { create: true }));
        dispatch(showCreateSegmentFulfillmentModalAction(false, null));
        toast.success('Created Tag Successfully');
      }
    } catch (e: unknown) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        e.message; // works, `e` narrowed to Error
        toast.error(e.message);
      }
    }
  };

  const getTags = async (status: string, no_users: boolean) => {
    const tagsResponse = await getSegmentList({ partnerId: partnerShortName, status, no_users });

    const tags = tagsResponse.data?.map((tags: FetchAllSegmentsData) => {
      return {
        value: tags.segment_id,
        label: tags.internal_name,
        description: tags.internal_description || 'No Description',
      };
    });
    setTags([...tags]);
  };

  useEffect(() => {
    if (!showSegmentFulfillmentModalData.show) {
      getTags('active', false);
    }
  }, [showSegmentFulfillmentModalData.show]);

  return (
    <div>
      <Dialog open={showSegmentFulfillmentModalData.show} onClose={handleClose} fullWidth disableRestoreFocus>
        <DialogContent className="modal-body">
          <div className="modal-ct">
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography variant="h5" align="center">
                    {'Allocate Tags'}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />

            <Grid container style={{ marginTop: '20px' }}>
              <Grid item xs={12}>
                <Form onSubmit={handleSubmit}>
                  {({ handleSubmit }) => (
                    <form method="post" onSubmit={handleSubmit}>
                      <Grid>
                        <Grid item xs={12} className={classes.gridStyle}>
                          <InputFieldsTooltip
                            title="User ID List"
                            description="Upload the CSV File that contains the User IDs of the player's you want to add or remove Tags from."
                          />
                        </Grid>
                        <Grid justifyContent={'center'} item xs={12} display={'flex'} className={classes.gridStyle}>
                          <CSVReader onUploadAccepted={onUploadAccepted} />
                        </Grid>
                        <Grid container xs={12}>
                          <Grid item xs={6}>
                            <InputFieldsTooltip
                              title="Select Tags"
                              description="Select the Tags that should be added or removed from the Uploaded Player List."
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <InputFieldsTooltip
                              title="Empty List"
                              description="Only display tag options that are not currently assigned to any users."
                            />

                            <Checkbox name={'empty_tags'} value={showEmptyTags} checked={showEmptyTags} onChange={handleEmptyTagsToggle} />
                          </Grid>
                          <Grid item xs={12} className={classes.gridStyle}>
                            <Autocomplete
                              multiple
                              disableCloseOnSelect
                              limitTags={4}
                              id="tags"
                              options={Tags}
                              getOptionLabel={(Tags) => {
                                return Tags.label;
                              }}
                              // getOptionDisabled={(option) => option.tagType === 1}
                              onChange={handleAutoComplete}
                              renderOption={(props, option, { selected }) => (
                                <Tooltip title={option.description}>
                                  <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                  </li>
                                </Tooltip>
                              )}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          {/*<Grid item xs={6} className={classes.gridStyle}>*/}
                          {/*  <InputFieldsTooltip title="Add" description="Should Tags be added to this group of Users." />*/}
                          {/*  <Field name="add" defaultValue={true} onChange={handleAction} component={CheckBoxWrapper} type="checkbox" />*/}
                          {/*</Grid>*/}

                          <Grid item xs={6} className={classes.gridStyle}>
                            <InputFieldsTooltip
                              title="Remove"
                              description="Would you like the selected Tags Removed from the the list of Users instead of added."
                            />

                            <Checkbox name={'remove'} value={isRemoveAction} checked={isRemoveAction} onChange={handleAction} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box
                        textAlign="center"
                        sx={{
                          marginBottom: '10 px',
                          justifyContent: 'space-between',
                        }}
                      >
                        {isScheduled == false && (
                          <Grid container spacing={1}>
                            <Grid item lg={6}>
                              <Button onClick={handleSchedule} variant="contained" color="primary" fullWidth>
                                <b>{'Schedule'}</b>
                              </Button>
                            </Grid>

                            <Grid item lg={6}>
                              <Button variant="contained" color="primary" type="submit" fullWidth>
                                <b>{'Allocate Now'}</b>
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        {isScheduled && (
                          <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.gridStyle}>
                              <Field
                                name="Start"
                                component={DateWrapper}
                                inputFormat="MM/dd/yyyy HH:mm:ss"
                                type="datetime-local"
                                label={
                                  <InputFieldsTooltip
                                    title="Start Allocation Job"
                                    description="What Date and Time should the Allocation be Scheduled for?"
                                  />
                                }
                              ></Field>
                            </Grid>

                            <Grid item lg={6}>
                              <Button variant="contained" color="primary" type="submit" fullWidth>
                                <b>{'Schedule Allocation'}</b>
                              </Button>
                            </Grid>
                            <Grid item lg={6}>
                              <Button variant="contained" color="primary" type="submit" fullWidth>
                                <b>{'Allocate Now'}</b>
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </form>
                  )}
                </Form>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateFulfillmentJob;
