import React from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { IDataGridRef } from '../DataTable/DataTable';

interface IBackendSearchBar {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  DataGridRef: React.MutableRefObject<IDataGridRef | null>;
  label?: string;
}

function BackendSearchBar({ search, setSearch, DataGridRef, label }: IBackendSearchBar) {
  return (
    <TextField
      fullWidth
      label={label ? label : 'Backend Search'}
      value={search}
      onChange={(event) => {
        setSearch(event.target.value);
      }}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                DataGridRef.current?.fetchData();
              }}
            >
              <SearchOutlined />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default BackendSearchBar;
