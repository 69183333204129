import React, { Fragment, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  FormControl,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { showCreateDailyReturnSetGroupModalAction, showCreateDailyReturnSetModalAction } from '../../../redux/actions/modalActions/modalActions';
import { Close, DragIndicator, Edit, ExpandMore, Help } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Field, Form } from 'react-final-form';
import InputWrapper from '../../UI/Input';
import InputFieldsTooltip from '../../InputFieldsTooltip';
import SelectWrapper from '../../UI/SelectWrapper';
import { composeValidators, maxLength, required } from '../../../utils/validations';
import useStyles from '../Sku/skuStyles';
import { Container, Draggable } from 'react-smooth-dnd';
import { API_URLS } from '../../../constants/url';
import { AxiosError } from 'axios';
import { startLoader, stopLoader } from '../../../utils/utils';
import axiosInstance from '../../../utils/apis';
import { toast } from 'react-toastify';
import { useCurrentPartner } from '../../../hooks/useCurrentPartner';
import '../../UI/Ui.css';
import {
  removeSetGroupMember,
  // removeSetGroupMember,
  // upsertDailyReturnSetGroupMember,
  upsertDailyRewardSetGroupData,
} from '../../../services/dailyReturn.service';
import { getPlatformList } from '../../../services/promoCode.service';
import { getAllTag } from '../../../services/allocateSku.service';
import { upsertDailyReturnSetGroupMember } from '../../../services/dailyReturn.service';

interface selectInterface {
  value: number;
  label: string;
}

interface DailyReturnTypeInterface {
  value: number;
  label: string;
  is_selected?: number;
}

interface DailyReturnResetStrategyInterface {
  value: number;
  label: string | undefined;
  is_selected?: number;
}

interface DailyReturnSpanInterface {
  value: number;
  label: string;
  is_selected?: number;
}

interface setGroupInterface {
  setGroupId: undefined;
  name: string;
  description: string;
  span: DailyReturnSpanInterface;
  type: DailyReturnTypeInterface;
  resetStrategy: DailyReturnResetStrategyInterface;
  resetStrategyId: number;
  whiteList: string[];
  blackList: string[];
  platforms: string[];
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

interface setInterface {
  setId: number;
  setGroupId: number;
  name: string;
  description: string | undefined;
  type: selectInterface;
  sequenceNum: number;
  params: Record<string, unknown> | undefined;
  metadata: Record<string, unknown> | undefined;
}

interface DailyReturnTypeInterface {
  value: number;
  label: string;
  is_selected?: number;
}

interface DailyReturnSpanInterface {
  value: number;
  label: string;
  is_selected?: number;
}

interface DailyReturnTypeData {
  value: number;
  label: string;
  is_selected?: number;
}

interface tagType {
  label: string;
  value: number;
  tagId: number;
  tagName: string;
  tagType: number;
  tagNames: string[];
}

interface platform {
  id: string;
  name: string;
}

interface ISKUTagData {
  tagId: number;
  name: string;
}

interface itemInterface {
  label: string;
  value: string;
  setId: number;
  name: string;
  description: string;
  type: selectInterface;
  sequenceNum: number;
}

interface setListItemsInterface {
  label: string;
  setId: number;
}

export const reorder = (list: setInterface[], startIndex: number | null, endIndex: number | null): setInterface[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex || 0, 1);
  result.splice(endIndex || 0, 0, removed);
  return result;
};

export const UpsertSetGroup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { partnerShortName } = useCurrentPartner();

  const dailyReturnSetGroupModalData = useSelector((state: RootState) => state.modalReducer.showDailyReturnSetGroup);

  const setGroupModalData = dailyReturnSetGroupModalData.data;
  const [selectedType, setSelectedType] = useState<string>('WHEEL');
  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const isReadable = dailyReturnSetGroupModalData?.isReadable;

  // create state for set data
  const [setGroupData, setFormData] = useState<setGroupInterface>();

  const [setData, setSetData] = useState<setInterface[]>([]);
  const [allSetData, setAllSetData] = useState<setInterface[]>([]);
  const [childSetList, setChildSetList] = React.useState<setListItemsInterface[]>([]);
  const [selectedChildSetList, setSelectedChildSetList] = React.useState<setListItemsInterface[]>([]);
  const [initialSelectedChildSetList, setInitialSelectedChildSetList] = React.useState<setListItemsInterface[]>([]);

  const [selectedPlatformList, setSelectedPlatformList] = React.useState<string[]>([]);
  const [selectedBlackListList, setSelectedBlackList] = React.useState<string[]>([]);

  const [selectedWhiteListList, setSelectedWhiteList] = React.useState<string[]>([]);

  const [platformListDisplay, setPlatformList] = React.useState<string[]>([]);
  const [tagListDisplay, setTagList] = React.useState<string[]>([]);
  const [Tags, setTags] = useState<tagType[]>([]);

  const [dailyReturnTypes, setDailyReturnTypes] = useState<DailyReturnTypeInterface[]>([]);
  const [dailyReturnSpanOptions, setDailyReturnSpanOptions] = useState<DailyReturnSpanInterface[]>([]);
  const [dailyReturnResetStrategyOptions, setDailyReturnResetStrategyOptions] = useState<DailyReturnResetStrategyInterface[]>([]);

  const [defaultGroupSpan, setDefaultGroupSpan] = useState<DailyReturnSpanInterface>();

  const [id, setId] = useState<number | undefined>();

  const handleChange = (event: SelectChangeEvent<typeof selectedPlatformList>) => {
    const {
      target: { value },
    } = event;
    setSelectedPlatformList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChildSetListChange = (event: SelectChangeEvent<typeof selectedChildSetList>) => {
    const {
      target: { value },
    } = event;

    const newChildSetList: setListItemsInterface[] = value as setListItemsInterface[];
    for (let i = 0; i < newChildSetList.length; i++) {
      // if item is not an object remove matching object from list
      if (typeof newChildSetList[i] === 'number') {
        // remove item from list
        const setIdClicked = newChildSetList[i] as unknown as number;
        const setItemIndex = selectedChildSetList.findIndex((item) => item.setId === setIdClicked);
        const setDataIndex = setData.findIndex((item) => item.setId === setIdClicked);
        if (setItemIndex > -1) {
          selectedChildSetList.splice(setItemIndex, 1);
          setData.splice(setDataIndex, 1);
        } else {
          const setItemIndex = childSetList.findIndex((item) => item.setId === setIdClicked);
          const setIndex = allSetData.findIndex((item) => item.setId === setIdClicked);
          selectedChildSetList.push(childSetList[setItemIndex]);
          setData.push(allSetData[setIndex]);
        }
      }
    }
    const newSelectedChildLists = [...selectedChildSetList];
    const newSetData = [...setData];

    setSetData(newSetData);
    setSelectedChildSetList(newSelectedChildLists);
  };

  const handleBlacklistChange = (event: SelectChangeEvent<typeof selectedBlackListList>) => {
    const {
      target: { value },
    } = event;
    setSelectedBlackList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleWhitelistChange = (event: SelectChangeEvent<typeof selectedBlackListList>) => {
    const {
      target: { value },
    } = event;
    setSelectedWhiteList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleClose = () => {
    dispatch(showCreateDailyReturnSetGroupModalAction(false, { success: true }));
    clearData();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearData = () => {
    setFormData({} as setGroupInterface);
    setSetData([]);
    setSelectedPlatformList([]);
    setSelectedBlackList([]);
    setSelectedWhiteList([]);
    setSelectedChildSetList([]);
    setPlatformList([]);
    setIsEditForm(false);
  };

  const getTag = async () => {
    const tagsResponse = await getAllTag();

    const tagList = tagsResponse.data.map((item: ISKUTagData) => {
      return item.name;
    });

    setTagList(tagList);

    const tags = tagsResponse.data?.map((tags: ISKUTagData) => {
      return {
        value: tags.tagId,
        label: tags.name,
        tagType: 0,
      };
    });
    setTags([...tags]);
  };

  const onDrop = ({ removedIndex, addedIndex }: { removedIndex: number | null; addedIndex: number | null }) => {
    const newList = (items: setInterface[]) => reorder(items, removedIndex, addedIndex);
    const newSetData = newList(setData);

    setSetData(newSetData);
  };

  const getDailyRewardSetGroupData = async (groupId: number | undefined) => {
    try {
      startLoader();
      setIsEditForm(true);
      const url = API_URLS.GET_DAILY_REWARD_SET_GROUP + (partnerShortName ? `?partnerId=${partnerShortName}&id=${groupId}` : `?id=${groupId}`);

      const setGroup = await axiosInstance.get(url);

      const sets = await axiosInstance.get(
        API_URLS.GET_DAILY_REWARD_SET_GROUP_SETS + (partnerShortName ? `?partnerId=${partnerShortName}&id=${groupId}` : `?id=${groupId}`),
      );

      const reOrderedSets = sets.data.sort((a: setInterface, b: setInterface) => a.sequenceNum - b.sequenceNum);

      const findGroupValue = dailyReturnTypes.find((data: DailyReturnTypeInterface) => data.label === setGroup.data.type)?.value;

      const findGroupSpanValue = dailyReturnSpanOptions.find((data: DailyReturnSpanInterface) => data.label === setGroup.data.type)?.value;

      const findResetStrategyLabel = dailyReturnResetStrategyOptions.find(
        (data: DailyReturnResetStrategyInterface) => data.value === setGroup.data.resetStrategy,
      )?.label;

      const selectPlatforms = setGroup?.data.platformNames.map((platform: string) => {
        const selectedPlatform = platformListDisplay.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*
          (code) => code === platform,
        );
        return selectedPlatform;
      });

      for (const platform of selectPlatforms) {
        if (platform === undefined) {
          const index = selectPlatforms.indexOf(platform);
          selectPlatforms.splice(index, 1);
        }
      }

      const selectBlacklist = setGroup?.data.blackListNames.map((tag: string) => {
        const selectedBlackListTags = Tags.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*
          (code) => code.label === tag,
        );
        return selectedBlackListTags?.label;
      });

      for (const tag of selectBlacklist) {
        if (tag === undefined) {
          const index = selectBlacklist.indexOf(tag);
          selectBlacklist.splice(index, 1);
        }
      }

      const selectWhitelist = setGroup?.data.whiteListNames.map((tag: string) => {
        const selectedWhiteListTags = Tags.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore*
          (code) => code.label === tag,
        );
        return selectedWhiteListTags?.label;
      });

      for (const tag of selectWhitelist) {
        if (tag === undefined) {
          const index = selectWhitelist.indexOf(tag);
          selectWhitelist.splice(index, 1);
        }
      }

      const selectChildSetList = reOrderedSets?.map((set: itemInterface) => {
        return {
          label: set?.name,
          setId: set?.setId,
        };
      });

      await setSelectedPlatformList(selectPlatforms);
      await setSelectedBlackList(selectBlacklist);
      await setSelectedWhiteList(selectWhitelist);

      setFormData({
        setGroupId: setGroup?.data.setGroupId,
        span: {
          value: Number(findGroupSpanValue),
          label: setGroup?.data.span,
        },
        blackList: setGroup?.data.blackList,
        platforms: setGroup?.data.platformNames,
        whiteList: setGroup?.data.whiteList,
        name: setGroup?.data.name,
        description: setGroup?.data.description,
        resetStrategyId: setGroup?.data.resetStrategy,
        resetStrategy: {
          value: Number(setGroup?.data.resetStrategy),
          label: findResetStrategyLabel,
        },
        type: {
          value: Number(findGroupValue),
          label: setGroup?.data.type,
        },
        metadata: setGroup?.data.metadata || {},
        params: setGroup?.data.params || {},
      });

      setSelectedChildSetList(selectChildSetList);
      setInitialSelectedChildSetList([...selectChildSetList]);

      setSetData(reOrderedSets);
      stopLoader();
    } catch (e: unknown | AxiosError) {
      if (typeof e === 'string') {
        toast.error(e.toUpperCase());
      } else if (e instanceof Error) {
        toast.error((e as AxiosError)?.response?.data?.message || e.message);
      } else {
        toast.error((e as AxiosError).response?.data?.message);
      }
    }

    return null;
  };

  const getSetOptions = async () => {
    let allSetOptions = await axiosInstance.get(API_URLS.GET_DAILY_REWARD_SETS);
    allSetOptions = allSetOptions.data;
    let childSetsListRes;
    if (setGroupModalData?.id) {
      const groupId = setGroupModalData?.id;
      const url = API_URLS.GET_DAILY_REWARD_SET_GROUP + (partnerShortName ? `?partnerId=${partnerShortName}&id=${groupId}` : `?id=${groupId}`);
      const setGroup = await axiosInstance.get(url);

      const updatedFilteredList = allSetOptions.filter(function (set: itemInterface) {
        return set.type == setGroup?.data.type;
      });

      childSetsListRes = updatedFilteredList?.map((set: itemInterface) => {
        return {
          label: set?.name,
          setId: set?.setId,
        };
      });
    } else {
      childSetsListRes = allSetOptions?.map((set: itemInterface) => {
        return {
          label: set?.name,
          setId: set?.setId,
        };
      });
    }

    childSetsListRes = childSetsListRes.filter((set: itemInterface) => {
      return set.type.label == selectedType;
    });
    await setAllSetData(allSetOptions);
    await setChildSetList([...childSetsListRes]);
  };

  const getPlatforms = async () => {
    const res = await getPlatformList({ url: API_URLS.GET_PLATFORM_LIST });
    if (res) {
      const platformList = res.data.map((item: platform) => {
        return item.name;
      });
      setPlatformList(platformList);
    } else {
      toast.error('Something Went Wrong');
    }
  };

  const handleSetGroupMemberSubmit = async (setGroupId: number | undefined) => {
    const setGroupMembers = selectedChildSetList;

    if (isEditForm) {
      const initialSetGroupMembers = initialSelectedChildSetList;
      const removedSetGroupMembers: setListItemsInterface[] = [];

      for (const initialSetGroupMember of initialSetGroupMembers) {
        const matchFound = setGroupMembers.find((setGroupMember) => setGroupMember.setId === initialSetGroupMember.setId);
        if (!matchFound) {
          removedSetGroupMembers.push(initialSetGroupMember);
        }
      }

      if (removedSetGroupMembers.length > 1) {
        for (const removedMember of removedSetGroupMembers) {
          await removeSetGroupMember({ setGroupId: setGroupModalData?.id, setId: removedMember.setId });
        }
      }
    }
    try {
      let sequence = 0;
      for (const set of setData) {
        sequence += 1;
        set.sequenceNum = sequence;
        const data = {
          setGroupId: setGroupModalData?.id || setGroupId,
          setId: set.setId,
          sequenceNum: set.sequenceNum,
        };
        await upsertDailyReturnSetGroupMember({ isEdit: false, data });
      }
    } catch (e) {
      toast.error('There was a problem creating or updating the set Group Member!');
    }
  };

  const handleDailyReturnSetSubmit = async (value: setGroupInterface) => {
    let data;
    let type;
    if (isEditForm) {
      type = 'updated';
      data = {
        setGroupId: Number(setGroupModalData?.id),
        name: value?.name,
        description: value?.description,
        type: value?.type?.label,
        span: value?.span?.label,
        resetStrategy: value?.resetStrategy?.value,
        platforms: selectedPlatformList,
        whitelist: selectedWhiteListList,
        blacklist: selectedBlackListList,
        metadata: setGroupData?.metadata || {},
        params: setGroupData?.params || {},
      };
    } else {
      type = 'created';
      setIsEditForm(false);
      data = {
        setGroupId: undefined,
        name: value?.name,
        description: value?.description,
        type: value?.type?.label,
        span: value?.span?.label,
        resetStrategy: value?.resetStrategy?.value,
        platforms: selectedPlatformList,
        whitelist: selectedWhiteListList,
        blacklist: selectedBlackListList,
        metadata: {},
        params: {},
      };
    }

    try {
      startLoader();
      const res = await upsertDailyRewardSetGroupData({
        isEdit: isEditForm,
        data,
      });

      if (res) {
        await handleSetGroupMemberSubmit(res.data);
        stopLoader();
        toast.success(`Successfully ${type} Set!`);
        handleClose();
      }
    } catch (e) {
      toast.error('There was a problem creating or updating the set!');
    }
  };

  useEffect(() => {
    const dailyReturnTypes: DailyReturnTypeData[] = [
      { value: 1, label: 'WHEEL' },
      { value: 2, label: 'LOTTO' },
    ];
    setDailyReturnTypes([...dailyReturnTypes]);

    const dailyReturnResetStrategies: DailyReturnResetStrategyInterface[] = [{ value: 1, label: 'Reset After Last' }];
    setDailyReturnResetStrategyOptions([...dailyReturnResetStrategies]);

    const dailyReturnSpans: DailyReturnSpanInterface[] = [
      { value: 1, label: 'DAILY' },
      { value: 2, label: 'WEEKLY' },
      { value: 3, label: 'ONCE' },
    ];
    setDailyReturnSpanOptions([...dailyReturnSpans]);
    setId(setGroupModalData?.id || undefined);
    getPlatforms();
    getTag();
    getSetOptions();
    if (setGroupModalData?.id) {
      getDailyRewardSetGroupData(setGroupModalData?.id).then(() => {
        const findSpanValue = dailyReturnSpanOptions.find((data: DailyReturnSpanInterface) => data.label === setGroupModalData.span)?.value;

        setDefaultGroupSpan({
          value: Number(findSpanValue) || 1,
          label: setGroupModalData?.span || '',
        });
      });
    }
  }, [setGroupModalData?.id, setGroupModalData]);

  return (
    <>
      <Dialog open={dailyReturnSetGroupModalData.show} onClose={handleClose} disableRestoreFocus fullWidth maxWidth="md">
        <div>
          <DialogContent>
            <Typography align="center" variant="h5" style={{ marginBottom: '10px' }}>
              {id === undefined ? 'Create Set Group' : 'Edit Set Group'}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right', marginTop: '-40px' }}>
              <Close />
            </IconButton>
            <Divider
              sx={{
                backgroundColor: 'black',
                borderBottomWidth: 2,
                marginTop: '25px',
                marginBottom: '25px',
              }}
            />
            <Box sx={{ mt: '20px' }}>
              <Grid container>
                <Grid xs={12}>
                  <Form onSubmit={handleDailyReturnSetSubmit}>
                    {({ handleSubmit }) => (
                      <form method="post" onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Fragment>
                                <Grid item xs={8} className={classes.gridStyle}>
                                  <Field
                                    component={InputWrapper}
                                    id="standard-basic"
                                    variant="outlined"
                                    defaultValue={setGroupModalData?.id ? setGroupData?.name : ''}
                                    name={'name'}
                                    type="text"
                                    readOnly={isReadable}
                                    placeholder="Please enter a Set Group name."
                                    label={
                                      <InputFieldsTooltip
                                        title="Set Name"
                                        description="The Set Name is a user-friendly name to easily identify the Wheel, this is not seen by players."
                                      />
                                    }
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <Field
                                    name="type"
                                    option={dailyReturnTypes}
                                    component={SelectWrapper}
                                    validate={required}
                                    readOnly={isReadable}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    initialValue={setGroupModalData?.id ? setGroupData?.type : dailyReturnTypes[0]}
                                    onChange={(e: any) => {
                                      console.log({ e });
                                      setSelectedType(e);
                                    }}
                                    focused
                                    label={
                                      <Fragment>
                                        Type
                                        <Tooltip
                                          sx={{
                                            position: 'relative',
                                            bottom: '10px',
                                            right: '5px',
                                          }}
                                          title="Select which type of set is being defined."
                                          placement="right-start"
                                        >
                                          <IconButton size="medium">
                                            <Help sx={{ fontSize: '20px' }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Fragment>
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200,
                                        },
                                      },
                                    }}
                                  >
                                    {}
                                  </Field>
                                </Grid>
                              </Fragment>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Field
                              name="description"
                              component={InputWrapper}
                              type="text"
                              rows={3}
                              readOnly={isReadable}
                              multiline
                              defaultValue={setGroupModalData?.id ? setGroupData?.description : ''}
                              validate={composeValidators(required, maxLength(128))}
                              placeholder="Description"
                              label={
                                <InputFieldsTooltip
                                  title="Description"
                                  description="Please enter a description for this set. This is not seen by players."
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={6} className={classes.gridStyle}>
                            <Field
                              name="span"
                              option={dailyReturnSpanOptions}
                              component={SelectWrapper}
                              validate={required}
                              readOnly={isReadable}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              initialValue={defaultGroupSpan}
                              focused
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                              label={
                                <InputFieldsTooltip title="Span" description="Select the length of time you would like the group to be active." />
                              }
                            >
                              {}
                            </Field>
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              name="resetStrategy"
                              option={dailyReturnResetStrategyOptions}
                              component={SelectWrapper}
                              validate={required}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              initialValue={setGroupModalData?.id ? setGroupData?.resetStrategy : dailyReturnResetStrategyOptions[0]}
                              readOnly={isReadable}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              }}
                              focused
                              label={
                                <InputFieldsTooltip title="Reset Strategy" description="Select the type of reset strategy to use for the Group" />
                              }
                            >
                              {}
                            </Field>
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="demo-multiple-checkbox-label">
                                <InputFieldsTooltip title="Select Sets" description="Which universes should this offer be limited to?" />
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                readOnly={isReadable}
                                value={selectedChildSetList}
                                onChange={handleChildSetListChange}
                                name={'childSets'}
                                input={<OutlinedInput label="Select Child Sets  " />}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  marginThreshold: 2,
                                }}
                                renderValue={(selected: setListItemsInterface[]) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value: setListItemsInterface) => (
                                      <Chip key={value.setId} label={value.label} />
                                    ))}
                                  </Box>
                                )}
                              >
                                {childSetList?.map((data: setListItemsInterface) => {
                                  return (
                                    <MenuItem key={data.setId} value={data.setId} id={data.label}>
                                      <Checkbox
                                        checked={selectedChildSetList.findIndex((item: setListItemsInterface) => item.setId === data.setId) > -1}
                                      />
                                      <ListItemText primary={data.label} />
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <fieldset>
                              <legend>
                                <Typography variant="body1">
                                  Sets
                                  {/*<UpsertDailyReturnSlot />*/}
                                </Typography>
                              </legend>
                              <List
                                className={'dailyRewardOverFlow'}
                                sx={{
                                  maxHeight: '350px',
                                  overflow: 'auto',
                                }}
                              >
                                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                                  {setData?.map((set, index) => (
                                    <Draggable key={index}>
                                      <ListItem
                                        sx={{
                                          border: 'solid',
                                          borderWidth: '1px',
                                          marginBottom: '5px',
                                          borderRadius: '25px',
                                          background: '#f5f5f5',
                                        }}
                                      >
                                        <Grid xs={12}>
                                          <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                              <ListItemText
                                                sx={{
                                                  marginTop: '15px',
                                                }}
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                primary={`${set?.name}`}
                                              />
                                            </Grid>

                                            <Grid item xs={6}>
                                              <ListItemText
                                                sx={{
                                                  marginTop: '15px',
                                                }}
                                                primary={`${set.description}`}
                                              />
                                            </Grid>
                                            <Grid xs={2}>
                                              <Grid container>
                                                <Grid item xs={12}>
                                                  <Tooltip title="Edit Set">
                                                    <Button
                                                      size="small"
                                                      style={{
                                                        marginLeft: 16,
                                                        marginTop: 12,
                                                      }}
                                                      onClick={() => {
                                                        dispatch(
                                                          showCreateDailyReturnSetModalAction(true, {
                                                            id: set?.setId,
                                                          }),
                                                        );
                                                      }}
                                                    >
                                                      <Edit color="secondary" />
                                                    </Button>
                                                  </Tooltip>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <ListItemSecondaryAction>
                                          <ListItemIcon className="drag-handle">
                                            <DragIndicator sx={{ cursor: 'pointer' }} />
                                          </ListItemIcon>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </Draggable>
                                  ))}
                                </Container>
                              </List>
                            </fieldset>
                          </Grid>

                          <Grid item xs={12} className={classes.gridStyle}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Advanced</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>Platforms, Black List, White List</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid>
                                  <Grid item xs={12} className={classes.gridStyle}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel id="demo-multiple-checkbox-label">
                                        <InputFieldsTooltip title="Platforms" description="Select Platforms Description" />
                                      </InputLabel>
                                      <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        readOnly={isReadable}
                                        value={selectedPlatformList}
                                        onChange={handleChange}
                                        name={'platforms'}
                                        input={<OutlinedInput label="Select Platforms  " />}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          },
                                          marginThreshold: 2,
                                        }}
                                        renderValue={(selected: string[]) => (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'wrap',
                                              gap: 0.5,
                                            }}
                                          >
                                            {selected.map((value: string) => (
                                              <Chip key={value} label={value} />
                                            ))}
                                          </Box>
                                        )}
                                      >
                                        {platformListDisplay?.map((data: string) => (
                                          <MenuItem key={data} value={data}>
                                            <Checkbox checked={selectedPlatformList.indexOf(data) > -1} />
                                            <ListItemText primary={data} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} className={classes.gridStyle}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel id="demo-multiple-checkbox-label">
                                        <InputFieldsTooltip title="Blacklist" description="Which users to exclude from this offer" />
                                      </InputLabel>
                                      <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        readOnly={isReadable}
                                        value={selectedBlackListList}
                                        onChange={handleBlacklistChange}
                                        name={'blacklist'}
                                        input={<OutlinedInput label="Select Blacklist  " />}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          },
                                          marginThreshold: 2,
                                        }}
                                        renderValue={(selected: string[]) => (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'wrap',
                                              gap: 0.5,
                                            }}
                                          >
                                            {selected.map((value: string) => (
                                              <Chip key={value} label={value} />
                                            ))}
                                          </Box>
                                        )}
                                      >
                                        {tagListDisplay?.map((data: string) => (
                                          <MenuItem key={data} value={data} disabled={selectedWhiteListList.indexOf(data) > -1}>
                                            <Checkbox
                                              checked={selectedBlackListList.indexOf(data) > -1 || selectedWhiteListList.indexOf(data) > -1}
                                              disabled={selectedWhiteListList.indexOf(data) > -1}
                                            />
                                            <ListItemText primary={data} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  {/*<Grid item xs={12}>*/}
                                  {/*  <Fragment>*/}
                                  {/*    White List*/}
                                  {/*    <Tooltip*/}
                                  {/*      sx={{*/}
                                  {/*        position: 'relative',*/}
                                  {/*        bottom: '10px',*/}
                                  {/*        right: '5px',*/}
                                  {/*      }}*/}
                                  {/*      title="Select the Universes that you would like this offer to be limited to."*/}
                                  {/*      placement="right-start"*/}
                                  {/*    >*/}
                                  {/*      <IconButton size="medium">*/}
                                  {/*        <Help sx={{ fontSize: '20px' }} />*/}
                                  {/*      </IconButton>*/}
                                  {/*    </Tooltip>*/}
                                  {/*  </Fragment>*/}
                                  {/*  <Autocomplete*/}
                                  {/*    multiple*/}
                                  {/*    disableCloseOnSelect*/}
                                  {/*    limitTags={4}*/}
                                  {/*    id="whitelist"*/}
                                  {/*    options={Tags}*/}
                                  {/*    getOptionLabel={(Tags) => {*/}
                                  {/*      return Tags.label;*/}
                                  {/*    }}*/}
                                  {/*    getOptionDisabled={(option) =>*/}
                                  {/*      option.tagType === 1*/}
                                  {/*    }*/}
                                  {/*    onChange={*/}
                                  {/*      handleAutoCompleteChangeWhiteList*/}
                                  {/*    }*/}
                                  {/*    renderOption={(*/}
                                  {/*      props,*/}
                                  {/*      option,*/}
                                  {/*      { selected },*/}
                                  {/*    ) => (*/}
                                  {/*      <li {...props}>*/}
                                  {/*        <Checkbox*/}
                                  {/*          icon={icon}*/}
                                  {/*          checkedIcon={checkedIcon}*/}
                                  {/*          style={{ marginRight: 8 }}*/}
                                  {/*          checked={selected}*/}
                                  {/*        />*/}
                                  {/*        {option.label}*/}
                                  {/*      </li>*/}
                                  {/*    )}*/}
                                  {/*    renderInput={(params) => (*/}
                                  {/*      <TextField {...params} />*/}
                                  {/*    )}*/}
                                  {/*  />*/}
                                  {/*</Grid>*/}

                                  <Grid item xs={12} className={classes.gridStyle}>
                                    <FormControl fullWidth variant="outlined">
                                      <InputLabel id="demo-multiple-checkbox-label">
                                        <InputFieldsTooltip title="Whitelist" description="Which universes should this offer be limited to?" />
                                      </InputLabel>
                                      <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        readOnly={isReadable}
                                        value={selectedWhiteListList}
                                        onChange={handleWhitelistChange}
                                        name={'blacklist'}
                                        input={<OutlinedInput label="Select Whitelist  " />}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                          },
                                          transformOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          },
                                          marginThreshold: 2,
                                        }}
                                        renderValue={(selected: string[]) => (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'wrap',
                                              gap: 0.5,
                                            }}
                                          >
                                            {selected.map((value: string) => (
                                              <Chip key={value} label={value} />
                                            ))}
                                          </Box>
                                        )}
                                      >
                                        {tagListDisplay?.map((data: string) => (
                                          <MenuItem key={data} value={data} disabled={selectedBlackListList.indexOf(data) > -1}>
                                            <Checkbox
                                              checked={selectedBlackListList.indexOf(data) > -1 || selectedWhiteListList.indexOf(data) > -1}
                                              disabled={selectedBlackListList.indexOf(data) > -1}
                                            />
                                            <ListItemText primary={data} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>

                          <Fragment>
                            <Grid item xs={12}>
                              <Button variant="contained" type="submit" fullWidth disabled={isReadable}>
                                {id === undefined ? 'Create Set Group' : 'Update Set Group'}
                              </Button>
                            </Grid>
                          </Fragment>
                        </Grid>
                      </form>
                    )}
                  </Form>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};
